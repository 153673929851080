.configButton {
  opacity: 0 !important;
  transition: all 0.2s linear !important;
}

.showOptions:hover .configButton {
  opacity: 1 !important;
}

.react-flow .react-flow__handle {
  width: 20px;
  height: 10px;
  border-radius: 5px;
  background-color: #1565c0;
  border-color: #1565c0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

div:has(> [name="validation"]) .react-flow__handle {
  display: none;
}


.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject div {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*:focus {
  outline: none;
}

.headerObject,
.iconHeaderObject {
  display: inline-block;
}

.headerDivider {
  margin: 0 !important;
  margin-top: -10px !important;
}

.react-flow__edge-buttonedge.selected .react-flow__edge-path {
  stroke: #0d47a1 !important;
  border-color: #0d47a1 !important;
  z-index: 2 !important;
}

.react-flow__edge-buttonedge.selected .edgebutton {
  border-color: #0d47a1 !important;
  z-index: 2 !important;
}

.react-flow__node {
  z-index: -1 !important;
}