/* width */
::-webkit-scrollbar {
    background: #f7f7f7;
    background-color: #f7f7f7 !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f7f7f7;
    background-color: #f7f7f7 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0b708c;
    background-color: #0b708c !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0b708c !important;
    background-color: #0b708c !important;
}

/* drop down de arquivos */
.sc-aXZVg.guZdik {
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    max-width: 100%;
    border: 2px dashed rgb(196 196 196);
  }

  @font-face {
    font-family: 'Blouse';
    src: url('./app/assets/fonts/Blouse.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}