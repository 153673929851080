.MuiPaper-root::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(151, 151, 151, 0.3);
    background-color: #ffffff;
}

.MuiPaper-root::-webkit-scrollbar {
    width: 10px;
    background: #0b708c;
    background-color: #0b708c !important;
}

.MuiPaper-root::-webkit-scrollbar-thumb {
    background: #0b708c;
    background-color: #0b708c !important;
}

.MuiPaper-root ::-webkit-scrollbar-thumb:hover {
    background: #0b708c !important;
    background-color: #0b708c !important;
}

.MuiFormControl-root  .MuiSelect-selectMenu{
    height: 24px!important;
    line-height: 24px!important;
    min-height: 24px!important;
    padding: 0!important;
    text-align: left;
    text-indent: 10px;
    margin: 0px 2px 6px 2px!important;
}

.blockedMessage {
    opacity: 0.5;
    cursor: not-allowed;
}

.blockedMessage div, .blockedMessage label {
    pointer-events: none;
}