//
// Scroll mixins
//

@mixin scrollbar-color($color, $hover-color: null) {
  // Firefox
  scrollbar-color: $custom-scrollbar-color;

  // Webkit
  &::-webkit-scrollbar-thumb {
    background-color: $custom-scrollbar-color;
  }

  // @if ($hover-color != null) {
  //   // Hover state
  //   &:hover {
  //     // Firefox
  //     scrollbar-color: $hover-color;

  //     // Webkit
  //     &::-webkit-scrollbar-thumb {
  //       background-color: $hover-color;
  //     }
  //   }
  // }
}
