#box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(151, 151, 151, 0.3);
    background-color: rgb(255, 255, 255);
}

#box::-webkit-scrollbar {
    width: 10px;
    background-color: #0b708c;
}

#box::-webkit-scrollbar-thumb {
    background-color: #0b708c;
}

.MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
}


.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
    background-color: #0000000f !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #0b708c !important;
}