#ChatBox::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(151, 151, 151, 0.3);
    background-color: #ffffff;
}

#ChatBox::-webkit-scrollbar {
    width: 10px;
    background: #0b708c;
    background-color: #0b708c !important;
}

#ChatBox::-webkit-scrollbar-thumb {
    background: #0b708c;
    background-color: #0b708c !important;
}

#ChatBox ::-webkit-scrollbar-thumb:hover {
    background: #0b708c !important;
    background-color: #0b708c !important;
}